import { getEnumMap, isEmpty, isEmptyOrWhiteSpace } from "@/utils/Common";
import store from "@/stores/index";
import moment from "moment";

let patentType: Map<string, any> | null = null;
let userLevel: Map<string, any> | null = null;
let messageType: Map<string, any> | null = null;

let feeStatus: Map<string, any> | null = null;
let operationType: Map<string, any> | null = null;
let activityType: Map<string, any> | null = null;

const map: any = {
  formatAmount(data: any, column: any, value: any) {
    if (isEmptyOrWhiteSpace(value)) {
      return value;
    }
    return `￥${(value + "").replace(/(?=(\B)(\d{3})+$)/g, ",")}`;
  },

  formatPatentType(data: any, column: any, value: any) {
    if (isEmpty(patentType)) {
      patentType = getEnumMap(
        store.getters["enums/getEnumsByKey"]("PatentType")
      );
    }
    return `<div class="cell-notice-wrapper ${value} ">${
      patentType ? patentType.get(value) : value
    }</div>`;
  },

  formatFeeOrderDate(
    data: any,
    column: any,
    value: any,
    rowIndex?: number,
    specialFee?: string
  ) {
    if (isEmptyOrWhiteSpace(value)) {
      return value;
    }
    const deadline = moment(value);
    const now = moment();
    if (!deadline.isValid()) {
      return value;
    }
    if (
      isEmptyOrWhiteSpace(specialFee) ||
      !data.hasOwnProperty("feeCategory") ||
      specialFee!.split(";").indexOf(data.feeCategory) === -1
    ) {
      return value;
    }
    const days = deadline.diff(now, "days");
    if (days <= 7) {
      return `<div class="cell-notice-wrapper" style="background-color: #000;color: #fff;">${value ||
        ""}</div>`;
    } else if (days <= 30) {
      return `<div class="cell-notice-wrapper" style="background-color: #FFFF00;color: #000;">${value ||
        ""}</div>`;
    } else {
      return `<div class="cell-notice-wrapper" style="background-color: #ED7D31;color: #fff;">${value ||
        ""}</div>`;
    }
  },

  formatFeeOrderDateForList(data: any, column: any, value: any) {
    if (isEmptyOrWhiteSpace(value)) {
      return value;
    }
    const deadline = moment(value);
    const now = moment();
    if (!deadline.isValid()) {
      return value;
    }
    const days = deadline.diff(now, "days");
    if (days < 0) {
      return `<div class="cell-notice-wrapper fee-expired">${value}<span class="flytag">已过期</span></div>`;
    } else if (days <= 7) {
      return `<div class="cell-notice-wrapper fee-week">${value}<span class="flytag">一周内</span></div>`;
    } else if (days <= 30) {
      return `<div class="cell-notice-wrapper fee-month">${value}<span class="flytag">一月内</span></div>`;
    } else if (days <= 90) {
      return `<div class="cell-notice-wrapper fee-threemonth">${value}<span class="flytag">三月内</span></div>`;
    } else if (days <= 180) {
      return `<div class="cell-notice-wrapper fee-sixmonth">${value}<span class="flytag">半年内</span></div>`;
    } else {
      return value;
    }
  },

  formatNoDeadline(noDeadline: any, closed: number) {
    if (!noDeadline) {
      return "--";
    }
    return closed ? "<s>已销除</s>" : "未销除";
  },

  formatDeadline(value: any, closed: number) {
    if (isEmptyOrWhiteSpace(value)) {
      return "--";
    }
    const deadline = moment(value);
    const now = moment();
    if (closed) {
      value = `<s>${value}</s>`;
    }
    if (!deadline.isValid()) {
      return value;
    }
    const days = deadline.diff(now, "days");
    if (days < 0) {
      return `<div class="cell-notice-wrapper fee-expired">${value}<span class="flytag">已过期</span></div>`;
    } else if (days <= 7) {
      return `<div class="cell-notice-wrapper fee-week">${value}<span class="flytag">一周内</span></div>`;
    } else if (days <= 30) {
      return `<div class="cell-notice-wrapper fee-month">${value}<span class="flytag">一月内</span></div>`;
    } else if (days <= 90) {
      return `<div class="cell-notice-wrapper fee-threemonth">${value}<span class="flytag">三月内</span></div>`;
    } else if (days <= 180) {
      return `<div class="cell-notice-wrapper fee-sixmonth">${value}<span class="flytag">半年内</span></div>`;
    } else {
      return value;
    }
  },

  formatPatentTypeOnlyText(data: any, column: any, value: any) {
    if (isEmpty(patentType)) {
      patentType = getEnumMap(
        store.getters["enums/getEnumsByKey"]("PatentType")
      );
    }
    return patentType && patentType.has(value) ? patentType.get(value) : value;
  },

  formatUserLevel(data: any, column: any, value: any) {
    if (isEmpty(userLevel)) {
      userLevel = getEnumMap(store.getters["enums/getEnumsByKey"]("UserType"));
    }
    return userLevel ? userLevel.get(value) : value;
  },

  formatActivityType(type: any) {
    if (isEmpty(activityType)) {
      activityType = getEnumMap(store.getters["enums/getEnumsByKey"]("ActivityType"));
    }
    return activityType ? activityType.get(type) : type;
  },

  formatMessageType(data: any, column: any, value: any) {
    if (isEmpty(messageType)) {
      messageType = getEnumMap(
        store.getters["enums/getEnumsByKey"]("MessageType")
      );
    }
    return messageType ? messageType.get(value) : value;
  },

  formatPatentNo(data: any, column: any, value: any) {
    if (isEmpty(patentType)) {
      patentType = getEnumMap(
        store.getters["enums/getEnumsByKey"]("PatentType")
      );
    }
    const type = data.appType;
    let dispType = "";
    switch (type) {
      case "FM":
        dispType = "发明";
        break;
      case "SYXX":
        dispType = "实用";
        break;
      case "WG":
        dispType = "外观";
        break;
      case "PCT":
        dispType = "PCT";
        break;
      default:
    }
    return `<div class="patent-no-wrapper"><div class="cell-patent-type ${type}">${dispType}</div><div class="cell-no">${data.appNo || "暂无申请号"}</div></div>`;
  },

  formatFeeStatus(data: any) {
    if (isEmpty(feeStatus)) {
      feeStatus = getEnumMap(store.getters["enums/getEnumsByKey"]("FeeStatus"));
    }
    const status = data.feeStatus;
    return feeStatus ? feeStatus.get(status) || "--" : status;
  },

  formatLastSyncTime(data: any) {
    const lastSyncTime = data.lastSyncTime;
    const createdTime = data.createdTime;
    return lastSyncTime < createdTime ? "--" : lastSyncTime;
  },

  formatPatentStatus(data: any) {
    return data.legalStatus || "--";
  },

  formatPatentFiles(data: any) {
    const patentFileList = data.patentFileList || [];
    const files = patentFileList.filter((file: any) => !isEmpty(file.remark1)).map((file: any) => {
      return `<tr>
          <td class="cell-event-td">${file.limitDate1}</td>
          <td class="cell-event-td">${file.remark1}</td>
          <td class="cell-event-td">${file.name}</td>
          <td class="cell-event-td">${file.status1}</td>
      </tr>`;
    });
    return files.length > 0 ? `<table class="cell-event-table">
      <thead>
        <tr>
          <th class="cell-event-th" style="width:15%;">期限</th>
          <th class="cell-event-th" style="width:35%;">事项</th>
          <th class="cell-event-th" style="width:30%;">文件</th>
          <th class="cell-event-th" style="width:20%;">状态</th>
        </tr>
      </thead>
      <tbody>
        ${files.join("")}
      </tbody>
      </table>` : "-";
  },

  formatOperationType(data: any) {
    if (isEmpty(operationType)) {
      operationType = getEnumMap(
        store.getters["enums/getEnumsByKey"]("OperationType")
      );
    }
    const action = data.operationType || "";
    return operationType ? operationType.get(action) || "--" : action;
  },

  formatPhone(data: any, column: any, value: any) {
    if (isEmptyOrWhiteSpace(value)) {
      return "";
    }
    const len = value.length;
    if (len > 6) {
      const phone = value.substring(0, 3) + "***" + value.substring(len - 3);
      if (store.getters["user/user"].userType === "ADMIN") {
        return `<div>
          <span id="phone-s-${data.id}" style="display:inline;">${phone}</span>
          <span id="phone-a-${data.id}" style="display:none;">${value}</span>
          <i class="el-icon-view" title="查看" style="padding-left:2px;cursor:pointer;" 
          onclick="document.getElementById('phone-s-${data.id}').style.display='none';
          document.getElementById('phone-a-${data.id}').style.display='inline';
          this.style.display='none'">
          </i>
        </div>`;
      }
      return phone;
    }
    return value;
  },

  formatEmail(data: any, column: any, value: any) {
    if (isEmptyOrWhiteSpace(value)) {
      return "";
    }
    if (value.indexOf("@") <= 0) {
      return value;
    }
    const values = value.split("@");
    if (values.length !== 2) {
      return value;
    }
    const email = values[0].substring(0, 1) + "***@" + values[1];
    if (store.getters["user/user"].userType === "ADMIN") {
      return `<div>
        <span id="email-s-${data.id}" style="display:inline;">${email}</span>
        <span id="email-a-${data.id}" style="display:none;">${value}</span>
        <i class="el-icon-view" title="查看" style="padding-left:2px;cursor:pointer;" 
        onclick="document.getElementById('email-s-${data.id}').style.display='none';
        document.getElementById('email-a-${data.id}').style.display='inline';
        this.style.display='none'">
        </i>
      </div>`;
    }
    return email;
  },

  formatUserInfo(user: any) {
    return user ? user.name : "--";
  },
  
  formatUserStatus(data: any) {
    return data.disabled
      ? "<font color='#F56C6C'>停用</font>"
      : "<font color='#67C23A'>正常</font>";
  }
};

export function mapFormatters(names: Array<string>) {
  if (!names || !names.length) {
    return {};
  }
  const funcs: {
    [key: string]: (data: any, column: any, value: any) => string;
  } = {};
  names.forEach(name => {
    funcs[name] =
      map[name] ||
      function(data: any, column: any, value: any) {
        return value;
      };
  });
  return funcs;
}

export default map;
